.treble-component-modal{
	@import '../assets/styles/rootStyles.scss';

	&.modal{
		.modal-dialog{

			&.default{
				max-width: 448px !important;
			}

			&.large{
				max-width: 568px !important;
			}

			.modal-content{
				color: $black-bis;
				font-family: $family-secondary;
				font-size: 15px;
				line-height: 21px;
				padding: 24px;

				.modal-header{
					padding: 0px;
					display: flex;
					flex-direction: row;
					border: none;

					.modal-title{
						font-weight: bold;
						font-size: 21px;
						line-height: 27px;

						p{
							font-family: $family-primary;
						}

					}

					.close-button{
						display: flex;
						flex-direction: row;
						margin-left: auto;
						i{
							width:24px !important;
							height:24px !important;
							cursor: pointer;
							filter: brightness(0) saturate(100%) invert(81%) sepia(7%) saturate(13%) hue-rotate(314deg) brightness(89%) contrast(86%);
						}		
					}
				}
				.modal-body{
					padding: 16px 0px 0px 0px;
					.danger{
						color:#F06450;
						padding: 0px 0px 16px 0px;
					}
				}

				.modal-footer{
					padding: 24px 0px 0px 0px;
					border: none;

					button{
						border:none;
						margin-left: 16px;
						font-family: $family-primary;
						font-weight: 600;
						font-size: 15px;
						line-height: 21px;
						border-radius: 2px;
						padding: 8px 16px;
						outline: none;

						&.default{
							color: $grey-dark;
							background-color: $white-ter;
							
						}
						&.danger{
							background-color: #F06450;
							color: $white;
						}
						&.treble{
							background-color: $lavender;
							color: $white;
						}
					}
				}
			}
		}
	}
}


