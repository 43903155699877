
.input {
  background: #fafafa;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 3px;
  height: 32px;

  &::placeholder {
    color: #b2b2b2;
    font-size: 15px;
  }
}

button.btn,
.btn {
  height: 32px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 16px;

  &.sm {
    padding: 4px;
  }

  &:hover {
    opacity: 0.7;
  }

  p {
    font-weight: normal;
    font-size: 15px;
  }

  &--black {
    background-color: #000000;

    p {
      color: white;
    }
  }

  &--white {
    background-color: white;
    border: 1px solid #e6e6e6;

    p {
      color: black;
    }
  }

  &--grey {
    background: #555555;

    color: $white;

    border: none;

    &-dark {
      background: $black-bis;

      color: $white;

      border: none;
    }
  }

  &--grey-20 {
    background-color: $grey-20;
    color: $white-ter;
  }

  &--has-icon {
    .icon {
      margin-right: 8px;
    }
  }

  &.m {
    padding: 9px;

    height: auto;

    font-size: 15px;
    line-height: 22px;
  }
}

.profile-image {
  width: 32px;
  height: 32px;
  background-color: #beafff;
  border: 1px solid #e6e6e6;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  border: 1px solid #e6e6e6;

  p {
    font-weight: 500;
    font-size: 13px;
    color: white;
    line-height: 22px;
  }
}
