@charset "utf-8";

@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

@font-face {
  font-family: space-grotesk;
  src: url("https://s3.amazonaws.com/assets.treble.ai/fonts/spacegrotesk/SpaceGrotesk-Regular.otf");
}

@font-face {
  font-family: hk-grotesk;
  src: url("https://s3.amazonaws.com/assets.treble.ai/fonts/hkgrotesk/HKGrotesk-Regular.otf");
}

// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$black-bis: #1a1a1a;
$grey: #b2b2b2;
$grey-20: #202020;
$grey-26: #262626;
$grey-39: #393939;
$white-ter: #F5F5F5;
$black: #000000;
$white: #ffffff;
$lavender-light: #C3C3FF;
$red: #EB4128;
$green-lighter: #DCF0E6;
$green-darker: #3C8C55;
$blue-lighter:#DCE7FF;
$blue-darker:#0046E1;
$yellow-lighter:#FFFAE6;
$yellow-darker:#FFB90F;
$red-lighter:#FFEBE6;
$red-darker:#EB4128;
$grey-dark: #767676;
$lavender:#9B9BFF;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$family-primary: "space-grotesk";
$family-secondary: "hk-grotesk";
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;
$black-bis: $black-bis;
$grey: $grey;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import "node_modules/bulma/bulma.sass";

@import "./textStyles.scss";
@import "./elements.scss";
@import "./classes.scss";
